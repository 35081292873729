<template>
    <div>
        <v-title title="Account Configurations - Add"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add account configuration</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="addConfiguration" v-if="selectedShop.applicationId !== '0'">
            <div v-if="selectedShop.applicationId !== '0'" class="container-fluid">
                <div class="row">
                    <div class="col-sm-9">
                        <mercur-card class="mt-n4 mb-4">
                            <h2 class="mt-1 font-weight-normal">Configuration type</h2>
                            <div class="mb-3" :class="getValidationClass($v, 'configurationType')">
                                <span class="ml-n1 mr-2">
                                    <input type="radio" id="system" @change="resetFields" value="SYSTEM" v-model="form.configurationType">
                                    <label for="system">SYSTEM</label>
                                </span>
                                <input type="radio" id="merchant" value="MERCHANT" @change="resetFields" v-model="form.configurationType">
                                <label for="merchant">MERCHANT</label>
                                <span class="form-error" v-if="!$v.form.configurationType.required">Type is required</span>
                            </div>
                        </mercur-card>
                        <!-- SYSTEM -->
                        <template v-if="form.configurationType === 'SYSTEM'">
                            <template v-if="systemConfigurations.length">
                                <mercur-card class="mb-4">
                                    <pretty-select
                                        class="mb-3"
                                        placeholder="Select System Configuration"
                                        :required="true"
                                        :options="systemConfigurations"
                                        :get-option-label="configuration => configuration.accountConfigurationName"
                                        v-model="selectedSystemConfiguration">
                                    </pretty-select>
                                    <template v-if="selectedSystemConfiguration && selectedSystemConfiguration.schema">
                                        <mercur-input disabled v-model="selectedSystemConfiguration.accountConfigurationName">
                                            System Configuration Name
                                        </mercur-input>
                                        <mercur-input disabled v-model="selectedSystemConfiguration.schema.type">
                                            System Type
                                        </mercur-input>
                                    </template>
                                </mercur-card>

                                <mercur-card v-if="selectedSystemConfiguration && selectedSystemConfiguration.schema" class="mb-4">
                                    <account-configuration-schema-collection
                                        v-model="selectedSystemConfiguration.schema.fields"
                                        :system-restricted="true"
                                    >
                                    </account-configuration-schema-collection>
                                </mercur-card>
                            </template>

                            <template v-else>
                                <span>There are no available system configurations.</span>
                            </template>
                        </template>

                        <!-- MERCHANT -->
                        <template v-if="form.configurationType === 'MERCHANT'">
                            <mercur-card class="mb-4">
                                <mercur-input v-model="form.configurationName" :class="getValidationClass($v, 'configurationName')">
                                    Account Configuration Name*
                                    <template #note>
                                        <span class="form-error" v-if="!$v.form.configurationName.required">Account configuration name is required</span>
                                    </template>
                                </mercur-input>
                                <pretty-select
                                    required
                                    placeholder="Schema Type"
                                    :options="schemaTypes"
                                    v-model="form.schemaType"
                                ></pretty-select>
                                <span class="pretty-select-error" v-if="!$v.form.schemaType.required">Schema type is required</span>
                            </mercur-card>

                            <!-- SCHEMA FIELDS FORM -->
                            <mercur-card>
                                <account-configuration-schema-collection v-model="form.schemaFields"></account-configuration-schema-collection>
                                <span class="error">{{ errorMsg }}</span>
                            </mercur-card>
                        </template>

                        <div class="text-right mt-4">
                            <mercur-button class="btn" :to="{name: 'CustomersConfigurationsWrapper'}">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" key="add-config" type="submit" :disabled="loading">Add configuration</mercur-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <template v-else>
            <div class="container-fluid mt-4">
                <p>Select a shop to add configuration</p>
            </div>
        </template>
    </div>
</template>
<script>

import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required, requiredIf } from 'vuelidate/lib/validators'
import PrettySelect from '@/components/utils/PrettySelect'
import { mapState } from 'vuex'
import AccountConfigurationSchemaCollection from '../../../components/elements/customers/AccountConfigurationFields/AccountConfigurationSchemaCollection'
export default {
    name: 'AddConfiguration',
    mixins: [FormMixin],
    components: { AccountConfigurationSchemaCollection, PrettySelect },
    data () {
        return {
            action: CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.ADD,
            schemaTypes: ['ALL_OF: Require all fields', 'ONE_OF: Only one field allowed at a time', 'ANY_OF: One or more fields can be filled in'],
            form: {
                configurationType: '',
                configurationName: '',
                schemaType: '',
                schemaFields: [],
            },
            systemConfigurations: [],
            selectedSystemConfiguration: {},
            isEditing: false,
            newSchemaValues: [],
            errorMsg: '',
        }
    },
    validations: {
        form: {
            configurationType: {
                required,
            },
            configurationName: {
                required: requiredIf(data => data.configurationType === 'MERCHANT'),
            },
            schemaType: {
                required,
            },
        },
        selectedSystemConfiguration: {
            required: requiredIf(data => data.configurationType === 'SYSTEM'),
        },
    },
    watch: {
        selectedSystemConfiguration (val) {
            if (val.schema) {
                this.form.schemaFields = val.schema.fields
                this.form.schemaType = val.schema.type
            }
        },
    },
    created () {
        this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.GET_AVAILABLE_SYSTEM_CONFIGURATIONS,
            CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            if (data) {
                this.$set(this, 'systemConfigurations', data)
            }
        })
    },
    computed: {
        ...mapState('shop', ['selectedShop']),
    },
    methods: {
        resetFields () {
            this.form.configurationName = ''
            this.form.schemaType = ''
            this.form.schemaFields = []
            this.selectedSystemConfiguration = {}
            this.isEditing = false
        },
        addConfiguration () {
            this.$v.$touch()
            if (this.$v.$invalid || this.$v.$error) {
                this.$root.$emit('notification:global', {
                    message: 'Some validations failed',
                    type: 'error',
                })
                return
            }

            this.errorMsg = ''
            if (this.form.schemaFields.length === 0) {
                this.errorMsg = 'Please add at least one field.'
                return
            }

            const splitSchemaType = this.form.schemaType.split(':')

            const payload = {
                accountConfigurationType: this.form.configurationType,
                accountConfigurationName: this.form.configurationName ? this.form.configurationName : this.selectedSystemConfiguration.accountConfigurationName,
                schema: {
                    type: splitSchemaType[0],
                    fields: [...this.form.schemaFields],
                },
            }

            this.post(CONFIG.API.ROUTES.CUSTOMERS.CONFIGURATIONS.ADD, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Account configuration was added',
                })

                this.$router.push({ name: 'CustomersConfigurationsWrapper' })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.account-configurations {
    &__schema-value {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10px;
    }
}

.pretty-select-container {
    z-index: 100000;
}
</style>
